import { axios } from '@/utils/request'

const api =  {
  dbDoc: {
    exportHtml: '/db-doc/export-html',
    exportWord: '/db-doc/export-word',
    exportMarkdown: '/db-doc/export-markdown',
  }
}

export function exportHtml() {
  return axios({
    url:  api.dbDoc.exportHtml,
    method: 'get',
    responseType: 'blob'
  })
}

export function exportWord() {
  return axios({
    url: api.dbDoc.exportWord,
    method: 'get',
    responseType: 'blob'
  })
}

export function exportMarkdown() {
  return axios({
    url: api.dbDoc.exportMarkdown,
    method: 'get',
    responseType: 'blob'
  })
}
